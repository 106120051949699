import { MenuIconWrapper } from './styles'
import React from 'react'
import { IMenuIconProps } from './types'
import { colors } from '../../styles/theme'

function AnimatedMenuIcon({
	isActive,
	onClick,
	primaryColor = colors.darkGrey
}: IMenuIconProps) {
	return (
		<MenuIconWrapper
			styles={{ primaryColor }}
			className={isActive ? 'active' : ''}
		>
			<div className='menu-wrapper' onClick={onClick}>
				<div className='menu'>
					<span className='line01'></span>
					<span className='line02'></span>
					<span className='line03'></span>
				</div>
			</div>
		</MenuIconWrapper>
	)
}

export default AnimatedMenuIcon
