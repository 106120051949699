import { ButtonWrapper } from './styles'
import { IButtonProps } from './types'
import React from 'react'
import { colors } from '../../styles/theme'

import { paddingToString } from '../../styles/functions'

function LabelButtonPrimary({
	id,
	children,
	className,
	primaryColor = colors.white,
	backgroundColor = colors.darkGrey,
	round = false,
	secondaryColor = colors.grey,
	fontSize = 16,
	fontFamily = 'sans-serif',
	padding = [16, 32, 16, 32],
	...rest
}: IButtonProps) {
	const paddingString = paddingToString(padding)

	const mergedStyles = {
		primaryColor,
		backgroundColor,
		round,
		secondaryColor,
		fontSize,
		fontFamily,
		padding: paddingString
	}

	return (
		<ButtonWrapper styles={mergedStyles} className={className}>
			<label htmlFor={id}>{children}</label>
			<input id={id} className='input' {...rest} />
		</ButtonWrapper>
	)
}

export default LabelButtonPrimary
